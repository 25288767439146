import { textStyles } from '../../theme'


export default {
  container: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    ...textStyles.text,
    cursor: 'pointer',
  },
}
