import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { Form, Formik } from 'formik'
import { withTheme } from 'theming'

import { mergeStyles } from '../../utils/StyleUtils'
import withMemo from '../../decorators/withMemo'
import { compileInitialValues } from '../../helpers/FormHelpers'
import { validateAll } from '../../helpers/ValidationHelpers'
import { useSafeCall } from '../../helpers/React'
import Submit from '../form/Submit'
import A from '../A'
import BackLink from '../BackLink'
import FormGenerator from '../FormGenerator'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FormForgotten = (props) => {
  const {
    classes: classesProp,
    className,
    initialValues,
    fieldsProps,
    messages,
    onSubmit,
    forgottenLinkProps,
    forgottenLinkLabel,
    backLinkProps,
    backLinkLabel,
    submitErrors,
    submitLabel,
    submitPendingLabel,
    submitPending,
    submitSuccess,
    submitSuccessLabel,
    sections,
    fields,
    rules,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  // handlers
  const handleValidate = useCallback((values) => validateAll(values, rules, messages), [messages, rules])
  const handleSubmit = useSafeCall(onSubmit)

  const renderForm = (formikProps) => (
    <Form
      noValidate
      autoComplete="off"
      onSubmit={formikProps.handleSubmit}
      className={classes.form}
    >
      <FormGenerator
        sections={sections}
        formikProps={formikProps}
        fieldsProps={fieldsProps}
      />
      {submitErrors && (
        <ul className={classes.formErrors}>
          {submitErrors.map((error, i) => (
            <li
              className={classes.error}
              key={i}
            >
              {error?.label || error?.message}
            </li>
          ))}
        </ul>
      )}
      {forgottenLinkProps && (
        <div className={classes.forgotten}>
          <A {...forgottenLinkProps}>
            {forgottenLinkLabel}
          </A>
        </div>
      )}
      <div className={classes.formActions}>
        <Submit
          label={submitLabel}
          className={classes.formSubmit}
          pendingLabel={submitPendingLabel}
          isDisabled={!formikProps.dirty}
          isPending={submitPending}
          isSuccess={!formikProps.dirty && submitSuccess}
          successLabel={submitSuccessLabel}
        />
      </div>
      {backLinkProps && (
        <div className={classes.backLink}>
          <BackLink {...backLinkProps}>
            {backLinkLabel}
          </BackLink>
        </div>
      )}
    </Form>
  )

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <Formik
          enableReinitialize
          validate={handleValidate}
          onSubmit={handleSubmit}
          initialValues={compileInitialValues(fields, initialValues)}
        >
          {renderForm}
        </Formik>
      </div>
    </div>
  )
}

FormForgotten.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  fieldsProps: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      help: PropTypes.string,
      placeholder: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.string, value: PropTypes.string })
      ),
    })
  ),
  messages: PropTypes.objectOf(PropTypes.string),
  onSubmit: PropTypes.func,
  submitPending: PropTypes.bool,
  submitPendingLabel: PropTypes.string,
  submitSuccess: PropTypes.bool,
  submitSuccessLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  submitErrors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      field: PropTypes.string,
    })
  ),
  backLinkProps: PropTypes.shape(A.propTypes),
  backLinkLabel: PropTypes.string,
  forgottenLinkProps: PropTypes.shape(A.propTypes),
  forgottenLinkLabel: PropTypes.string,
  sections: FormGenerator.propTypes.sections,
  fields: PropTypes.arrayOf(PropTypes.object),
  rules: PropTypes.objectOf(PropTypes.string),
}

FormForgotten.defaultProps = {
  className: null,
  classes: {},
  initialValues: null,
  fieldsProps: null,
  messages: null,
  onSubmit: () => null,
  submitPending: false,
  submitPendingLabel: null,
  submitSuccess: false,
  submitSuccessLabel: null,
  submitLabel: null,
  submitErrors: null,
  backLinkProps: null,
  backLinkLabel: '',
  forgottenLinkProps: null,
  forgottenLinkLabel: '',
  sections: null,
  fields: null,
  rules: null,
}

export default withMemo()(withTheme(FormForgotten))
