import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { withTheme } from 'theming'

import { mergeStyles } from '../../utils/StyleUtils'
import withMemo from '../../decorators/withMemo'
import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Paragraph = (props) => {
  const { classes: classesProp, className, text } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <MarkdownText
          className={classes.text}
          text={text}
        />
      </div>
    </div>
  )
}

Paragraph.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
}

Paragraph.defaultProps = {
  className: null,
  classes: {},
  text: null,
}

export default withMemo()(withTheme(Paragraph))
