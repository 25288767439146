import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import routes, { Router } from '../routes'
import { selectors as AuthSelectors } from '../redux/auth/redux'


export const useNavPath = () => {
  const router = useRouter()
  const isConnected = useSelector(AuthSelectors.isConnected)

  return useMemo(() => ({
    links: [
      ...(isConnected ? [] : [{
        linkProps: {
          label: 'Scholarship',
          href: Router.getRouteUrl(routes.scholarship),
        },
        isCurrent: router.pathname === routes.scholarship.component,
      }]),
      {
        linkProps: {
          label: 'About',
          href: Router.getRouteUrl(routes.about),
        },
        isCurrent: router.pathname === routes.about.component,
      },
      {
        linkProps: {
          label: 'Submit your school',
          href: Router.getRouteUrl(routes.submitSchool),
        },
        isCurrent: router.pathname === routes.submitSchool.component,
      },
    ],
  }), [isConnected, router.pathname])
}

export const useApplicationNavPath = () => ({})
