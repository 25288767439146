import { colors, tablet, fonts } from '../../theme'


export default {
  container: {
    ...fonts.UniversRoman,
    fontSize: '1.2rem',
    textAlign: 'center',
    letterSpacing: '0.05em',
  },
  containerSmall: {
    letterSpacing: '0.03em',
    ...tablet({
      fontSize: '1.2rem',
    }),
  },
  wrapper: {
    overflow: 'auto hidden',
    scrollbarWidth: 'none',
    MsOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  },
  links: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    margin: '0',
    textTransform: 'uppercase',
    '& li': {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
    '& li:first-child': {
      marginLeft: 'auto',
    },
    '& li:last-child': {
      marginRight: 'auto',
    },
    // variants
    '$containerSmall &': {
      textTransform: 'none',
    },
    // mq
    ...tablet({
      '& li': {
        marginLeft: '2rem',
        marginRight: '2rem',
      },
    }),
  },
  link: {
    display: 'block',
    padding: '1rem',
    whiteSpace: 'nowrap',
    lineHeight: 1,
    '& span': {
      display: 'block',
      borderBottom: '1px solid transparent',
    },
  },
  linkInactive: {
    pointerEvents: 'none',
  },
  linkCurrent: {
    pointerEvents: 'none',
    '& span': {
      borderBottomColor: colors.pureBlack,
    },
  },
}
