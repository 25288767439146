import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import withMemo from '../../decorators/withMemo'
import { generateFieldsProps, getFieldTranslations, compileRules, defineField } from '../../helpers/FormHelpers'
import ScholarshipTemplate from '../../templates/Scholarship'
import Password from '../../components/form/fields/Password'
import { useHeader, useFooter } from '../../hooks/useAppLayout'
import { useNavPath } from '../../hooks/useNavPath'

import sagas from './sagas'
import { actions as scholarshipActions, selectors } from './redux'


const Scholarship = () => {
  const { t } = useTranslation()
  const headerProps = useHeader()
  const footerProps = useFooter()
  const navPathProps = useNavPath()
  const dispatch = useDispatch()
  const register = useSelector(selectors.register)
  const submitErrors = register?.errors
  const submitSuccess = register?.success
  const submitPending = register?.pending

  const layoutProps = {
    theme: {
      maxWidth: 640,
    },
  }

  const pageTitleProps = {
    text: t('scholarship'),
  }

  const paragraphProps = {
    text: t('scholarship_page_introduction'),
  }

  const sections = [
    {
      type: 'fields',
      content: [
        defineField({
          name: 'code',
          validation: 'required',
          props: {
            type: 'text',
          },
        }),
      ],
    },
    {
      type: 'fields',
      content: [
        defineField({
          name: 'email',
          validation: 'required|email',
          props: {
            type: 'text',
          },
        }),
      ],
    },
    {
      type: 'fields',
      content: [
        defineField({
          name: 'password',
          validation: 'required',
          component: Password,
          props: {
            type: 'password',
          },
        }),
      ],
    },
  ]
  const fields = sections
    .filter((section) => section.type === 'fields')
    .map((thisFields) => thisFields.content)
    .flat()
  const rules = compileRules(fields)
  const formRegistrationProps = useMemo(
    () => ({
      fieldsProps: generateFieldsProps(fields, (field) => {
        const fieldProps = getFieldTranslations(t, `registration_form_${field.name}`)

        return fieldProps
      }),
      messages: {
        required: t('form_error_required'),
        email: t('form_error_email'),
      },
      submitLabel: t('registration_form_submit_label'),
      submitPendingLabel: t('form_submit_pending_label'),
      submitSuccessLabel: t('form_submit_success_label'),
      submitErrors,
      submitSuccess,
      submitPending,
      sections,
      fields,
      rules,
      onSubmit: (payload) => dispatch(scholarshipActions.register(payload)),
    }),
    [fields, t, submitErrors, submitSuccess, submitPending, sections, rules, dispatch]
  )

  const scholarshipTemplateProps = {
    headerProps,
    navPathProps,
    footerProps,
    layoutProps,
    pageTitleProps,
    paragraphProps,
    formRegistrationProps,
  }


  return (<ScholarshipTemplate {...scholarshipTemplateProps} />)
}

Scholarship.getInitialProps = async(ctx) => ctx.store.saga.run(sagas.init, ctx).toPromise()

Scholarship.propTypes = {}

Scholarship.defaultProps = {}

export default withMemo()(Scholarship)
