import React from 'react'
import PropTypes from 'prop-types'

import LayoutDefault from '../../components/LayoutDefault'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import PageTitle from '../../components/PageTitle'
import NavPath from '../../components/NavPath'
import Paragraph from '../../components/Paragraph'
import FormRegistration from '../../components/FormRegistration'
import withMemo from '../../decorators/withMemo'


const ScholarshipTemplate = ({ headerProps, navPathProps, footerProps, layoutProps, pageTitleProps, paragraphProps, formRegistrationProps }) => (
  <LayoutDefault
    {...layoutProps}
    header={(
      <Header {...headerProps} />
    )}
    nav={(
      <NavPath
        {...navPathProps}
      />
    )}
    title={(
      <PageTitle {...pageTitleProps} />
    )}
    content={(
      <>
        <Paragraph {...paragraphProps} />
        <FormRegistration
          {...formRegistrationProps}
        />
      </>
    )}
    footer={(
      <Footer {...footerProps} />
    )}
  />
)

ScholarshipTemplate.propTypes = {
  headerProps: PropTypes.any,
  navPathProps: PropTypes.any,
  footerProps: PropTypes.any,
  layoutProps: PropTypes.any,
  pageTitleProps: PropTypes.any,
  paragraphProps: PropTypes.any,
  formRegistrationProps: PropTypes.any,
}

ScholarshipTemplate.defaultProps = {
  headerProps: null,
  navPathProps: null,
  footerProps: null,
  layoutProps: null,
  pageTitleProps: null,
  paragraphProps: null,
  formRegistrationProps: null,
}

export default withMemo()(ScholarshipTemplate)
