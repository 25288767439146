/* eslint-disable complexity */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'

import ActionButton from '../../ActionButton'
import withMemo from '../../../decorators/withMemo'
import icons from '../../Icon/icons'
import { useSafeCall } from '../../../helpers/React'
import { mergeStyles } from '../../../utils/StyleUtils'

import styles from './styles'


const useStyles = createUseStyles(styles)
const Submit = (props) => {
  const {
    className,
    label,
    successLabel,
    disabledLabel,
    isSuccess,
    isDisabled,
    isPending,
    isLight,
    isOutline,
    pendingLabel,
    color,
    onClick,
    type,
    theme,
    icon,
    classes: classesProp,
    ...otherProps
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const labelText = isPending && pendingLabel
    ? pendingLabel
    : isSuccess && successLabel
      ? successLabel
      : isDisabled && disabledLabel
        ? disabledLabel
        : label

  const handleClick = useSafeCall(onClick)

  return (
    <div className={cx(classes.container, className, isPending && !isSuccess && classes.pending)}>
      <ActionButton
        color={color}
        label={labelText}
        className={cx(classes.button, isPending && classes.pending)}
        isDisabled={isDisabled}
        isSuccess={isSuccess || isPending}
        icon={isPending ? icons.Refresh : isSuccess ? icons.Check : icon}
        onClick={handleClick}
        type={type}
        theme={theme}
        {...otherProps}
      />
    </div>
  )
}

Submit.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.string,
  successLabel: PropTypes.string,
  disabledLabel: PropTypes.string,
  pendingLabel: PropTypes.string,
  isSuccess: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isPending: PropTypes.bool,
  isLight: PropTypes.bool,
  isOutline: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.func,
}

Submit.defaultProps = {
  classes: {},
  className: '',
  label: '',
  color: '',
  theme: 'primary',
  type: 'submit',
  successLabel: '',
  disabledLabel: '',
  pendingLabel: '',
  isSuccess: false,
  isDisabled: false,
  isPending: false,
  isLight: false,
  isOutline: false,
  onClick: () => null,
  icon: null,
}

export default withMemo()(Submit)
