import React from 'react'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import Icon from '../../../Icon'
import icons from '../../../Icon/icons'
import Text from '../Text'
import withMemo from '../../../../decorators/withMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Password = (props) => {
  const classes = useStyles(props)
  const { name, ...others } = props

  const [display, setDisplay] = React.useState(false)

  return (
    <Text
      {...others}
      name={name}
      type={display ? 'text' : 'password'}
      endOrnament={(
        <Icon
          icon={icons.Eye}
          onClick={() => setDisplay(!display)}
          className={cx(classes.icon, display ? classes.iconOff : classes.iconOn)}
        />
      )}
    />
  )
}

Password.styles = styles

Password.propTypes = Text.propTypes

Password.defaultProps = Text.defaultProps

export default withMemo()(Password)
