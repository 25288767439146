import { textStyles } from '../../theme'


export default {
  container: {
  },
  wrapper: {
    maxWidth: (props) => `${props?.theme?.maxWidth / 10}rem` || 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: (props) => props?.theme?.paragraphTextAlign || 'justify',
    textJustify: 'inter-character',
  },
  text: {
    ...textStyles.text,
    '& a, & a:visited, & a:active': {
      textDecoration: 'underline',
    },
    '& a:hover': {
      textDecoration: 'none',
    },
    '& strong': {
      fontWeight: 'bold',
    },
    '& > *': {
      margin: 0,
    },
    '& > * + *': {
      marginTop: '1rem',
    },
    '& h1': {
      ...textStyles.h1,
      textAlign: 'center',
    },
    '& h2, & h3, & h4, & h5': {
      ...textStyles.h3,
    },
    '& > h1:not(:first-child), & > h2:not(:first-child), & > h3:not(:first-child), & > h4:not(:first-child), & > h5:not(:first-child)': {
      marginTop: '2.5rem',
    },
    '& > h1 + *': {
      marginTop: '2.5rem',
    },
    '& > h2 + *, & > h3 + *, & > h4 + *, & > h5 + *': {
      marginTop: '1rem',
    },
    '& li': {
      position: 'relative',
    },
    '& ol': {
      '& > * + *': {
        marginTop: '1rem',
      },
    },
    '& ol > li': {
      paddingLeft: '20px',
      counterIncrement: 'list-number',
      '&::before': {
        content: 'counter(list-number) ". "',
        position: 'absolute',
        top: 0,
        left: 0,
      },
      '& > p:not(:first-child)': {
        marginTop: '1rem',
      },
    },
    '& ul': {
      '& > * + *': {
        marginTop: '0.5rem',
      },
    },
    '& ul > li': {
      '&::before': {
        content: '"-"',
        marginRight: '0.5rem',
      },
      '& > p:not(:first-child)': {
        marginTop: '1rem',
      },
    },
    '& li li': {
      marginLeft: '1rem',
    },
  },
}
