import { formErrorsStyles, tablet } from '../../theme'


export default {
  container: {
  },
  wrapper: {
    maxWidth: (props) => `${props?.theme?.maxWidth / 10}rem` || 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  form: {
    '& > *': {
      maxWidth: '33.5rem',
      margin: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '& > * + *': {
      marginTop: '2.5rem',
    },
    '& > * + $formActions': {
      marginTop: '5.5rem',
      maxWidth: 'none',
    },
    ...tablet({
      '& > * + $formActions': {
        marginTop: '7rem',
      },
    }),
  },
  formErrors: {
    ...formErrorsStyles(),
  },
  formActions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
  },
}
