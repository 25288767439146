import React, { useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import { mergeStyles } from '../../utils/StyleUtils'
import withMemo from '../../decorators/withMemo'
import A from '../A'

import styles from './styles'


const useStyles = createUseStyles(styles)

const NavPath = (props) => {
  const { classes: classesProp, className, links, variant } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const $thisWrapper = useRef()
  const $thisMenu = useRef()

  // scroll to the current element in navpath
  useEffect(() => {
    if (typeof window !== 'undefined' && $thisMenu.current.scrollWidth > $thisMenu.current.offsetWidth) {
      const currentElementOffset = $thisMenu.current.querySelector(`.${classes.linkCurrent}`).offsetLeft

      $thisWrapper.current.scrollLeft = currentElementOffset
    }
  }, [classes.linkCurrent])

  return (
    <nav className={cx(classes.container, className, variant === 'small' ? classes.containerSmall : classes.containerBig)}>
      <div
        className={classes.wrapper}
        ref={$thisWrapper}
      >
        <ul
          className={classes.links}
          ref={$thisMenu}
        >
          {links.map((link, index) => (
            <li key={index}>
              <A
                className={cx(
                  classes.link,
                  link.isCurrent === true && classes.linkCurrent,
                  link.isInactive === true && classes.linkInactive
                )}
                href={link.linkProps.href}
                route={link.linkProps?.route?.name}
                routeParams={link.linkProps?.route?.params}
                onClick={
                  link.isCurrent === true || link.isInactive === true
                    ? (e) => {
                      e.preventDefault()
                    }
                    : null
                  }
              >
                <span>
                  {link.linkProps.label}
                </span>
              </A>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

NavPath.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.shape({
    isCurrent: PropTypes.bool,
    isActive: PropTypes.bool,
    linkProps: PropTypes.shape(A.propTypes),
  })),
  variant: PropTypes.oneOf(['big', 'small']),
}

NavPath.defaultProps = {
  className: null,
  classes: {},
  links: null,
  variant: 'big',
}

export default withMemo()(NavPath)
