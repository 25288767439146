import { colors } from '../../../../theme'


export default {
  container: {},
  icon: {
    cursor: 'pointer',
    width: '2.5rem',
    height: '1.6rem',
    '& svg': {
      fill: 'currentColor',
    },
  },
  iconOn: {
    color: colors.midGrey,
    '&:hover': {
      color: colors.pureBlack,
    },
  },
  iconOff: {
    color: colors.pureBlack,
  },
}
