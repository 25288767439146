import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import { mergeStyles } from '../../utils/StyleUtils'
import withMemo from '../../decorators/withMemo'
import A from '../A'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BackLink = (props) => {
  const { classes: classesProp, className, linkProps, children } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  if (!linkProps) {
    return null
  }

  return (
    <A
      className={cx(classes.container, className)}
      {...linkProps}
    >
      ‹
      {' '}
      {children}
    </A>
  )
}

BackLink.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  linkProps: PropTypes.shape(A.propTypes),
  children: PropTypes.node.isRequired,
}

BackLink.defaultProps = {
  className: null,
  classes: {},
  linkProps: null,
}

export default withMemo()(BackLink)
