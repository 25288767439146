export default {
  '@global': {
    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  },
  pending: {
    '& svg': {
      animation: 'rotate 2s linear infinite;',
    },
  },
}
